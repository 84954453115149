import React from 'react';
import { Router } from 'react-router-dom';
import { render } from 'react-dom';

import { history } from './utilities/history';
import { jwtInterceptor } from './utilities/jwt.interceptor';
import { initFacebookSdk } from './utilities/inititalize-facebook-sdk';
import { App } from './App'
import { errorInterceptor } from './utilities/error.interceptor';

initFacebookSdk().then(startApp);
jwtInterceptor();
errorInterceptor();

function startApp() {
  render(
    <Router history={history}>
      <App />
    </Router>,
    document.getElementById('app')
  );
}