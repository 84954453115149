import React, { useState } from 'react';
import { accountService } from '../services/account.service';

function Login({ history }) {

    const [error, setError] = useState('');
    function editFacebook() {
        window.FB.login(function (response) {
            if (response.status == 'connected') {
                accountService.apiAuthenticate('facebook', response.authResponse.accessToken)
                .then(function() {
                    history.push('/edit');
                })
                .catch(err => setError(err));
            }
        });
    }

    function resetPassword(){
        history.push('/reset')
    }

    return (
        <div className="center">
            <h4>Forgot login or password?</h4>
            <p>If you forgot your FanStable username or password - or created an account with Facbook - reset it here:</p>
            <button className="facebook" onClick={ editFacebook }>
                <svg width="24" height="24" viewBox="0 0 24 24" className="facebook-icon" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H12.6031L12.606 14.0176L10.2624 14.0078L10.2638 11.3352L12.606 11.3282C12.601 10.6166 12.6024 9.90434 12.6045 9.19277C12.6095 8.33145 12.8527 7.43848 13.4335 6.78387C14.046 6.07512 14.9952 5.74746 15.912 5.72145C16.6813 5.7102 17.4519 5.74324 18.2169 5.8241L18.2176 8.27449C17.7057 8.2752 17.1931 8.2752 16.6813 8.27379C15.8213 8.28609 15.4017 8.58492 15.3671 9.47402L15.3615 11.3289L18.0896 11.3296L17.7289 14.0197L15.3615 14.0169L15.365 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5Z" fill="#003499"/>
                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H12.6031L12.606 14.0176L10.2624 14.0078L10.2638 11.3352L12.606 11.3282C12.601 10.6166 12.6024 9.90434 12.6045 9.19277C12.6095 8.33145 12.8527 7.43848 13.4335 6.78387C14.046 6.07512 14.9952 5.74746 15.912 5.72145C16.6813 5.7102 17.4519 5.74324 18.2169 5.8241L18.2176 8.27449C17.7057 8.2752 17.1931 8.2752 16.6813 8.27379C15.8213 8.28609 15.4017 8.58492 15.3671 9.47402L15.3615 11.3289L18.0896 11.3296L17.7289 14.0197L15.3615 14.0169L15.365 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0)">
                    </g>
                </svg>
                Reset Facebook
            </button>
            {error &&
                            <div className="error">{error.message}</div>
                        }
            <button onClick={ resetPassword }> Reset Password </button>
        </div>
    );

}

export { Login };