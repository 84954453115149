import { accountService } from "../services/account.service";

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID

export function initFacebookSdk() {
    return new Promise(resolve => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: facebookAppId,
                cookie: true,
                xfbml: true,
                version: 'v8.0'
            });

            resolve();

            // window.FB.getLoginStatus(function(authenticationResponse) {
            //     console.log(authenticationResponse);
            //     if (authenticationResponse.status === 'connected') {
            //         accountService.facebookToken = authenticationResponse.authResponse.accessToken;
            //         resolve();
            //     } else {
            //         resolve();
            //     }
            // });
        };

        (function (targetDocument, tagName, id) {
            var js, fjs = targetDocument.getElementsByTagName(tagName)[0];
            if (targetDocument.getElementById(id)) { 
                return; 
            }
            js = targetDocument.createElement(tagName);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    });
}