import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { Login } from './login/Login';
import { Edit } from './edit/Edit';
import { Final } from './final/Final';
import { PrivateRoute } from './components/PrivateRoute';

import './App.css';
import { Reset } from './reset/Reset';
import { Sent } from './sent/Sent';

function App() {

  const pathname = useLocation().pathname || '';

  return (
    <div className="full-height">
      <img src="background.png" className="background" />
      <div className="branding">
        <img src="logo.png" className="logo"/>
      </div>
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/edit" component={Edit} />
        <Route path="/final" component={Final} />
        <Route path="/reset" component={Reset} />
        <Route path="/sent" component={Sent} />
        <Redirect from="*" to="/login" />
      </Switch>
    </div>
  );
}

export { App };
