import { BehaviorSubject } from "rxjs";
import axios from "axios";
import { history } from "../utilities/history";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const accountSubject = new BehaviorSubject(null);

export const accountService = {
    apiAuthenticate,
    validateEmail,
    updateIdentity,
    resetPassword,
    account: accountSubject.asObservable(),
    get accountValue () { 
        return accountSubject.value; 
    },
    facebookToken: ''
};


async function apiAuthenticate(provideType, accessToken) {
    console.log(accessToken);
    let accountData = {
        access_token: accessToken,
        provider_type: provideType
    };

    const response = await axios.post(`${baseUrl}auth`, accountData);

    const account = response.data;
    accountSubject.next(account);
}

async function validateEmail(email) {
    const response = await axios.get(`${baseUrl}email_exists/${email}`);

    return response.data.exists;
}

async function updateIdentity(email, password) {
    if (!await validateEmail(email)) {
        let formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        const response = await axios.post(`${baseUrl}update_identity/${accountSubject.value.user.id}`, formData);
    } else {
        throw new Error("Email already connected to a FanStable account.")
    }

}

async function resetPassword(email) {
    let data = {
        auth_id: email
    }

    const response = await axios.post(`${baseUrl}reset_password`, data);
}