import React from 'react';

function Final(){
    return (
        <div className="center">
            <h4>Success!</h4>
            <p>You are now able to login to your FanStable accout with the username and passsword you provided.</p>
            <p>You can close this webpage now.</p>
        </div>
    );
}

export { Final };