import React from 'react';

function Sent(){
    return (
        <div className="center">
            <h4>Email Sent</h4>
            <p>Check your email for further instructions.</p>
            <p>You can close this webpage now.</p>
        </div>
    );
}

export { Sent };