import React, { useState } from 'react';
import { accountService } from '../services/account.service';
import { useForm } from "react-hook-form";

function Reset({ history }) {

    const { register, handleSubmit, setValue, formState } = useForm();
    function onSubmit(data) {
        accountService.resetPassword(data.email)
        .then(history.push('/sent'))
    }

    return (
        <div className="center">
            <h4>Reset your passsword</h4>
            <p>Please provide us with your FanStable email and we will send you further instructions.</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <label>Email</label>
                <input type="text" {...register('email', { required: true })} placeholder="Tap here to edit"></input>
                <button type="submit">Send Email</button>
            </form>
        </div>
    );
}

export { Reset };