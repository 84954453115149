import axios from 'axios';

export function errorInterceptor() {
    axios.interceptors.response.use(null, (error) => {
        const { response }  = error;
        if (!response) {
            console.error(error);
            return;
        }

        if (response.status === 401) {
            throw new Error("There are no FanStable accounts linked to your Facebook account.");
        }
    })
}