import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { accountService } from '../services/account.service';

function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route {...rest} render={properties => {
            const account = accountService.accountValue;
            if (!account) {
                return <Redirect to={{ pathname: '/login', state: { from: properties.location } }} />
            }

            return <Component {...properties} />
        }} />
    );
}

export { PrivateRoute };