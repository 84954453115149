import React, { useState } from 'react';
import { accountService } from '../services/account.service';
import { useForm } from "react-hook-form";

function Edit({ history }) {

    const { register, handleSubmit, setValue, formState } = useForm();

    const [error, setError] = useState('');
    function onSubmit(data) {
        accountService.updateIdentity(data.email, data.password)
        .then(history.push('/final'))
        .catch(err => setError(err))
    }

    return (
        <div className="center">
            <h4>Edit your account</h4>
            <p>Please provide us with an email and password to attach to your account.</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <label>Email</label>
                <input type="text" {...register('email', { required: true })} placeholder="Tap here to edit"></input>
                <label>Password</label>
                <input type="password" {...register('password', { required: true })} placeholder="Tap here to edit"></input>
                {error &&
                            <div className="error">{error.message}</div>
                        }
                <button type="submit">Save</button>
            </form>
        </div>
    );
}

export { Edit };